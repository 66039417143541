const isTouchDevice = () => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};

// Define adjustInputSize as a custom hook
const useAdjustInputSize = () => {
  const isIframe = window !== window.top;
  const isMobile = isTouchDevice();

  // Adjust input size based on device detection and iframe status
  if (isIframe && isMobile) {
    // If it's a mobile device in an iframe, use larger inputs
    return "md";
  } else if (isIframe && !isMobile) {
    return "sm";
  } else if (!isIframe && isMobile) {
    return "md";
  } else {
    return "sm";
  }
};

export default useAdjustInputSize;
