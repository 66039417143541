import React, { useState, useEffect, useContext } from "react";
import {
  NativeSelect,
  TextInput,
  Stack,
  Grid,
  Group,
  Input,
  Text,
  Select,
} from "@mantine/core";
import FloatingLabelInput from "./floating-label-input";
import { IconChevronDown } from "@tabler/icons-react";
import { DataContext } from "../context/DataContext";
const DateOfBirthSelect = ({ onDobChange, ...props }) => {
  const { text } = useContext(DataContext);
  const [dob, setDob] = useState({
    day: "",
    month: "",
    year: "",
  });

  useEffect(() => {
    if (props.value && props.value.length === 8) {
      const year = props.value.substring(0, 4);
      const month = props.value.substring(4, 6);
      const day = props.value.substring(6, 8);
      setDob({ day, month, year });
    }
  }, [props.value]);

  useEffect(() => {
    onDobChange(dob);
  }, [dob]);

  const months = [
    { value: "", label: "" },
    { value: "01", label: text.general.months.january },
    { value: "02", label: text.general.months.february },
    { value: "03", label: text.general.months.march },
    { value: "04", label: text.general.months.april },
    { value: "05", label: text.general.months.may },
    { value: "06", label: text.general.months.june },
    { value: "07", label: text.general.months.july },
    { value: "08", label: text.general.months.august },
    { value: "09", label: text.general.months.september },
    { value: "10", label: text.general.months.october },
    { value: "11", label: text.general.months.november },
    { value: "12", label: text.general.months.december },
  ];

  const handleInputChange = (field, value) => {
    // Check if the value is being cleared or if it's a single digit that needs padding
    if (field === "day") {
      // If the value is not empty, determine whether to pad it
      let finalValue = value;
      if (value && value.length === 1 && value !== "0") {
        finalValue = `0${value}`;
      }
      // Remove leading zero if the length of the value is two
      else if (value && value.length === 3 && value.startsWith("0")) {
        finalValue = value.substring(1);
      }
      // Allow clearing the field or handle input "0" directly
      else if (value === "0" || value === "") {
        finalValue = "";
      }
      if (finalValue.length <= 2) {
        setDob((prevState) => ({
          ...prevState,
          day: finalValue,
        }));
      }
    } else {
      // For other fields, update the state directly with the provided value
      setDob((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  return (
    <>
      <Group>
        <Text fz="xs" c="dimmed">
          {text.general.dateOfBirth}
        </Text>
        {props.error && (
          <Text fz="xs" c="red">
            {props.error}
          </Text>
        )}
      </Group>

      <Grid gutter={8}>
        <Grid.Col span={5}>
          <NativeSelect
            size="lg"
            comboboxProps={{ withinPortal: true }}
            data={months}
            placeholder="Pick one"
            onChange={(e) => handleInputChange("month", e.currentTarget.value)}
            label={text.general.month}
            value={dob.month || ""}
            //rightSection={<IconChevronDown stroke={1} size={15} />}
            styles={(theme) => ({
              input: {
                //height: "20px",
                fontSize: "18px",
                paddingTop: "5px",
              },
              label: {
                position: "absolute",
                pointerEvents: "none",
                fontSize: "11px",
                paddingLeft: "10px",
                paddingTop: "5px",
                zIndex: "1",
                color: "#868e96",
              },
              root: {
                position: "relative",
              },
            })}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <FloatingLabelInput
            placeholder="DD"
            label={text.general.day}
            value={dob.day}
            onChange={(e) => handleInputChange("day", e.currentTarget.value)}
            maxLength={2}
            minLength={2}
            type="number"
            pattern="\d*"
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <FloatingLabelInput
            placeholder="YYYY"
            label={text.general.year}
            value={dob.year}
            onChange={(e) => handleInputChange("year", e.currentTarget.value)}
            maxLength={4}
            minLength={4}
            type="number"
            pattern="\d*"
          />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default DateOfBirthSelect;
