import React, { useState, useEffect, useContext } from "react";
import {
  LoadingOverlay,
  Box,
  Container,
  NativeSelect,
  Alert,
  Select,
  Text,
  TextInput,
  Button,
  Group,
  Stack,
  Center,
  Radio,
  Paper,
  Loader,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { DataContext } from "../../../context/DataContext";
import {
  IconShieldCheck,
  IconShieldCancel,
  IconPhoneCheck,
  IconArrowLeft,
} from "@tabler/icons-react";
import "./style.css";
import useStatusCheck from "../../../hooks/useStatusCheck";
import DataCollectionForm from "../../../components/data-collection-form";
export default function IDMatchPlus() {
  const { data } = useContext(DataContext);
  const [isQuiz, setIsQuiz] = useState(false);
  const [scoring, setScoring] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [template, setTemplate] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [animation, setAnimation] = useState("");
  const [gettingNextQuestion, setGettingNextQuestion] = useState(false);
  const [isQuestionsSubmitted, setIsQuestionsSubmitted] = useState(false);

  useStatusCheck(scoring, false, "idmatch/plus");
  const formConfig = {
    fields: [
      {
        name: "fn",
        label: "First Name",
        group: "name",
        initialValue: "",
        validation: (value) =>
          value.length < 2 ? "First name must have at least 2 letters" : null,
      },
      {
        name: "ln",
        label: "Last Name",
        group: "name",
        initialValue: "",
        validation: (value) =>
          value.length < 2 ? "Last name must have at least 2 letters" : null,
      },
      {
        name: "address",
        label: "Address",
        type: "address",
        validation: (value) => {
          let addrError = "Address is required";

          if (
            value.addr &&
            value.city &&
            value.state &&
            value.zip.length === 5
          ) {
            // The date is valid.
            addrError = null;
          }

          return addrError;
        },
      },
      {
        name: "dob",
        label: "Date of Birth",
        type: "date",
        validation: (value) => {
          const dobPattern =
            /^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/;
          const isDobFormatCorrect = dobPattern.test(value);

          // Initialize dobError with a default error message.
          let dobError = "Date of Birth is required";

          if (isDobFormatCorrect) {
            const year = parseInt(value.substring(0, 4), 10);
            const month = parseInt(value.substring(4, 6), 10) - 1; // JS months are 0-indexed
            const day = parseInt(value.substring(6, 8), 10);

            const date = new Date(year, month, day);

            if (
              date.getFullYear() === year &&
              date.getMonth() === month &&
              date.getDate() === day
            ) {
              // The date is valid.
              dobError = null;
            }
          }
          return dobError;
        },
      },
    ],
  };

  const handleApiResponse = (v) => {
    const questions = v.idr5_response?.output?.questions?.questions;

    if (Array.isArray(questions) && questions.length > 0) {
      setQuestions(questions); // Store the whole question objects
      setIsQuiz(true);
      setTemplate(v.idr5_response?.continuations?.questions?.template);
    }
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setGettingNextQuestion(true);
      setAnimation("question-exit-active");
      setTimeout(() => {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setAnimation("question-enter-active");
        setGettingNextQuestion(false);
      }, 500); // The delay should match your animation duration
    } else {
      setIsQuestionsSubmitted(true);
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setGettingNextQuestion(true);
      setAnimation("question-exit-active-back");
      setTimeout(() => {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
        setAnimation("question-enter-active-back");
        setGettingNextQuestion(false);
      }, 500); // The delay should match your animation duration
    }
  };

  const handleRadioChange = (value) => {
    const questionId = questions[currentQuestionIndex].id;
    setSelectedAnswers({
      ...selectedAnswers,
      [questionId]: [value], // The value is directly the selected answer's text
    });
  };

  const continueKba = async () => {
    setScoring(true);
    data.isContinue = true;
    data.template = template;
    data.template.answers = selectedAnswers;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v3/stable/api/frontend/idmatchPlus`,
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      )
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {
          //console.log("here is the data", data);
        });
    } catch (error) {
      alert("Something Went Wrong! Please try again.");
    }
  };

  if (scoring) {
    return <LoadingOverlay visible={true} />;
  }
  if (questions.length > 0) {
    const currentQuestion = questions[currentQuestionIndex];
    const currentQuestionId = questions[currentQuestionIndex].id;
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {gettingNextQuestion && (
          <Center
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <Loader variant="dots" />
            {/* Or use a Spinner from Mantine */}
            {/* <Loader /> */}
          </Center>
        )}
        <Box className={`question-container ${animation}`}>
          <Stack mb="xl">
            <Group>
              <Text fz="sm" tt="uppercase" fw={500}>
                Identity verification
              </Text>
              <Text fz="xs" c="dimmed">
                {currentQuestionIndex + 1} / 4
              </Text>
            </Group>

            <Text fz={25} fw={700}>
              {currentQuestion.prompt}
            </Text>
          </Stack>
          <Paper withBorder p="md" style={{ backgroundColor: "#fafafa" }}>
            <Radio.Group
              value={
                selectedAnswers[currentQuestionId]
                  ? selectedAnswers[currentQuestionId][0]
                  : null
              }
              onChange={handleRadioChange} // Passing the handler directly
            >
              {currentQuestion.answers.map((answer, index) => (
                <Radio
                  mt={index > 0 ? 20 : 0}
                  size="md"
                  key={index}
                  value={answer.text}
                  label={answer.text}
                />
              ))}
            </Radio.Group>
          </Paper>
        </Box>
        <Box>
          <Stack
            spacing={5}
            //position={currentQuestionIndex > 0 ? "apart" : "right"}
            mt="md"
          >
            <Button
              size="lg"
              onClick={currentQuestionIndex === 3 ? continueKba : handleNext}
              disabled={
                !selectedAnswers[currentQuestionId] ||
                selectedAnswers[currentQuestionId].length === 0
              }
            >
              {currentQuestionIndex === 3 ? "Submit Answers" : "Next"}
            </Button>
            {currentQuestionIndex > 0 && (
              <Button
                size="xs"
                variant="subtle"
                leftIcon={<IconArrowLeft size={14} />}
                onClick={handleBack}
                disabled={currentQuestionIndex === 0}
              >
                Previous
              </Button>
            )}
          </Stack>
        </Box>
      </Box>
    );
  }

  return (
    <DataCollectionForm
      config={formConfig}
      title="Challenge Questions"
      subtitle="Please provide your legal name, accurate Date of Birth, and SSN for the highest chance of success."
      buttonText={"Start Verification"}
      icon={
        <>
          <IconShieldCheck size={40} stroke={1.5} color="teal" />
        </>
      }
      componentKey={"idmatch/plus"}
      endpoint="/v3/stable/api/frontend/idmatchPlus"
      reAttempts={1}
      onApiResponse={(v) => handleApiResponse(v)}
    />
  );
}
