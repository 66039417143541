import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Group,
  Center,
  Stack,
  Text,
  Container,
  rem,
  LoadingOverlay,
  Flex,
  ThemeIcon,
  Burger,
  Drawer,
  ActionIcon,
  Button,
  Switch,
  Divider,
  UnstyledButton,
  Alert,
  Notification,
} from "@mantine/core";
import {
  IconCalendarBolt,
  IconPhone,
  IconCreditCard,
  IconFaceId,
  IconChevronRight,
  IconId,
  IconDeviceMobileStar,
  IconDotsVertical,
  IconDots,
  IconUser,
  IconHttpOptions,
  IconSettingsBolt,
  IconHelp,
  IconShieldBolt,
  IconInfoCircleFilled,
  IconX,
} from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";

import { DataContext } from "../../../context/DataContext";
import IDMatch from "../../idmatch/coppa";
import AgeMatch from "../process";
import DigitalId from "../../digital-id";
import PhoneMatch from "../../phonematch";
import AgeEstimation from "../estimation";
import DcamsModular from "../../dcams/modular";
const getIconAndLabel = (choice) => {
  switch (choice) {
    case "idmatch/coppa":
      return {
        icon: <IconUser stroke={2} />,
        iconColor: "cyan",
        label: "Partial SSN",
        description:
          "Enter your details so we can confirm them with trusted and verified data sources.",
        component: <IDMatch />,
      };
    case "phonematch":
      return {
        icon: <IconPhone stroke={2} />,
        iconColor: "indigo",
        path: "phonematch",
        description:
          "Verify your phone number associated with your identity and then confirm possession.",
        label: "Phone Number",
        component: <PhoneMatch />,
      };
    case "agematch/estimate":
      return {
        icon: <IconFaceId stroke={2} />,
        iconColor: "red",
        label: "Facial Age Estimation",
        component: <AgeEstimation />,
        description:
          "Take a picture of your face which will be used to estimate your age.",
      }; // Adjust the icon as needed
    case "dcams/modular":
      return {
        icon: <IconId stroke={2} />,
        iconColor: "grape",
        label: "Government ID",
        component: <DcamsModular />,
        description:
          "Take a picture of your ID or use an identity wallet to confirm your identity",
      };
    case "id_doc_mobile":
      return {
        icon: <IconDeviceMobileStar stroke={2} />,
        iconColor: "teal",
        label: "Mobile Drivers License or ID Wallet",
        component: <DigitalId />,
      }; // Adjust the icon as needed
    default:
      return { icon: null, label: choice };
  }
};

export default function AgeMatchModular() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data,
    settings,
    setSessionState,
    routeSequence,
    dispatch,
    modularFailedAtLeastOne,
  } = useContext(DataContext);
  const [choices, setChoices] = useState([]);
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [choiceVisibility, setChoiceVisibility] = useState({});
  const [currentChoice, setCurrentChoice] = useState(null);
  const [hoveredChoice, setHoveredChoice] = useState(null);

  useEffect(() => {
    const currentRoute = settings.journey.route.find(
      (route) => route.path === "agematch/modular"
    );

    if (routeSequence) {
      const paths = routeSequence.map((route) => route.path);
      setChoices(paths);

      if (routeSequence.length === 1) {
        setCurrentChoice(routeSequence[0].path);
      } else {
        setCurrentChoice(null);
      }
    }
  }, [settings.journey.route]);

  const handleChoiceClick = (choice) => {
    //setCurrentChoice(choice);
    dispatch({ type: "SET_SELECTED_COMPONENT", payload: choice });
    navigate(choice);
  };

  return (
    <>
      <Stack spacing={30}>
        <Paper padding="md">
          <IconShieldBolt stroke={1} size={50} />
          <Text fz={24} fw={700} mt="md">
            Select a Verification Method
          </Text>
        </Paper>

        <Stack>
          {choices.map((choice, index) => {
            const { icon, label, iconColor, description } =
              getIconAndLabel(choice);
            return (
              <Paper
                p="sm"
                withBorder
                key={index}
                style={{
                  //backgroundColor: "#fafafa",
                  transition: "all 0.3s ease",
                  cursor: "pointer",
                  transform:
                    hoveredChoice === choice ? "scale(1.01)" : "scale(1)",
                  boxShadow:
                    hoveredChoice === choice
                      ? "0px 5px 15px rgba(0, 0, 0, 0.1)"
                      : "none",
                }}
                onMouseEnter={() => setHoveredChoice(choice)}
                onMouseLeave={() => setHoveredChoice(null)}
                onClick={() => handleChoiceClick(choice)}
              >
                <Group position="apart" noWrap>
                  <Group noWrap>
                    <ThemeIcon
                      variant="filled"
                      color={iconColor}
                      size="lg"
                      radius="md"
                    >
                      {icon}
                    </ThemeIcon>
                    <Text>{label}</Text>
                  </Group>
                  <IconChevronRight stroke={1} />
                </Group>
              </Paper>
            );

            return null; // don't render anything for hidden choices
          })}
        </Stack>
      </Stack>
    </>
  );
}
