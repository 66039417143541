import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "../../context/DataContext";
import {
  Text,
  Group,
  Box,
  Paper,
  Stack,
  Image,
  Select,
  TextInput,
  Center,
  Flex,
  Divider,
  ScrollArea,
  LoadingOverlay,
  Container,
  Skeleton,
} from "@mantine/core";
import YotiIcon from "../../assets/yoti_icon.svg";
import LaWalletIcon from "../../assets/LA_Wallet.svg";
import FlSmartID from "../../assets/florida_smart_id.svg";
import UtahMobileID from "../../assets/utah_mid.jpg";
import MaxID from "../../assets/max_id_logo.png";
import Verimi from "../../assets/verimi.png";
import CIE from "../../assets/cie.png";
import { IconSearch } from "@tabler/icons-react";
import YotiButton from "./yoti";

const WalletProviderItem = ({ walletProvider, onClick }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Paper
      withBorder
      p="sm"
      onClick={onClick}
      style={{
        transition: "all 0.3s ease",
        cursor: "pointer",
        transform: isHovered ? "scale(1.02)" : "scale(1)",
        boxShadow: isHovered ? "0px 5px 15px rgba(0, 0, 0, 0.1)" : "none",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Group noWrap>
        <Image
          src={walletProvider.icon}
          width={30}
          onLoad={() => setImageLoaded(true)}
          style={{ display: imageLoaded ? "block" : "none" }}
        />
        {!imageLoaded && <Skeleton width={30} height={30} />}
        <Text>Verify with {walletProvider.name}</Text>
      </Group>
    </Paper>
  );
};

export default function DigitalId() {
  const { data, settings, setSessionState } = useContext(DataContext);
  const [selectedCountry, setSelectedCountry] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [selectedPaper, setSelectedPaper] = useState(null);
  const [isYotiReady, setIsYotiReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleYotiReady = () => {
    setIsYotiReady(true);
  };

  const paperData = [
    {
      name: "Yoti",
      icon: YotiIcon,
      country: "global",
      component: <YotiButton onReady={handleYotiReady} />,
    },
    {
      name: "LA Wallet",
      icon: LaWalletIcon,
      country: "us",
    },
    {
      name: "Florida Smart ID",
      icon: FlSmartID,
      country: "us",
    },
    {
      name: "Utah Mobile ID",
      icon: UtahMobileID,
      country: "us",
    },
    {
      name: "Verimi",
      icon: Verimi,
      country: "de",
    },
    {
      name: "CIE",
      icon: CIE,
      country: "it",
    },
  ];

  const options = [
    { label: "🌍 All", value: "all" },
    { label: "🌐 Global", value: "global" },
    { label: "🇺🇸 USA", value: "us" },
    { label: "🇩🇪 Germany", value: "de" },
    { label: "🇮🇹 Italy", value: "it" },
  ];

  const filteredData = paperData.filter(
    (paper) =>
      (selectedCountry === "all" || paper.country === selectedCountry) &&
      paper.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const handlePaperClick = (paper) => {
    setSelectedPaper(paper);
  };

  useEffect(() => {
    console.log("selected", selectedPaper);
    console.log("yoti ready", isYotiReady);
    if (selectedPaper && !isYotiReady) {
      setLoading(true);
    }
    if (selectedPaper && isYotiReady) {
      setLoading(false);
    }
  }, [selectedPaper, isYotiReady]);

  if (selectedPaper) {
    return (
      <>
        <LoadingOverlay visible={loading ? true : false} overlayOpacity={1} />

        <Flex style={{ height: "100vh" }} align="center" justify="center">
          <Stack align="center" spacing={0}>
            <Box style={{ width: "450px" }}>
              {selectedPaper.component || (
                <Text>Component for {selectedPaper.name} is not available</Text>
              )}
            </Box>
            <Text c="dimmed" fz="xs">
              Powered by
            </Text>
            <Box style={{ width: "75px" }}>
              <Image src={MaxID} w={75} />
            </Box>
            <Text c="dimmed" fz="sm" align="center">
              A Veratad Solution
            </Text>
          </Stack>
        </Flex>
      </>
    );
  }

  return (
    <>
      <Center p="lg">
        <Box style={{ width: "450px" }}>
          <Stack spacing="md">
            <Text fz={25} fw={700}>
              Select your wallet
            </Text>
            <Stack>
              <Select
                size="md"
                data={options}
                value={selectedCountry}
                onChange={(value) => setSelectedCountry(value)}
                placeholder="Choose country"
              />
              <TextInput
                size="md"
                icon={<IconSearch size={14} />}
                placeholder="Search by name"
                value={searchText}
                onChange={(event) => setSearchText(event.currentTarget.value)}
              />
            </Stack>
            <Divider
              c="dimmed"
              label="Select your wallet"
              labelPosition="center"
              my="xs"
            />
            <Paper withBorder p="md">
              <ScrollArea h={350} offsetScrollbars scrollHideDelay={500}>
                <Stack spacing="md">
                  {filteredData.length > 0 ? (
                    filteredData.map((walletProvider, index) => (
                      <WalletProviderItem
                        key={index}
                        walletProvider={walletProvider}
                        onClick={() => handlePaperClick(walletProvider)}
                      />
                    ))
                  ) : (
                    <Text align="center" c="dimmed">
                      0 results found
                    </Text>
                  )}
                </Stack>
              </ScrollArea>
            </Paper>
          </Stack>

          <Stack align="center" spacing={0} mt="xl">
            <Box style={{ width: "75px" }}>
              <Image src={MaxID} w={75} />
            </Box>
            <Text c="dimmed" fz="sm" align="center">
              A Veratad Solution
            </Text>
          </Stack>
        </Box>
      </Center>
    </>
  );
}
