import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Loader, Text, Stack, Group, LoadingOverlay } from "@mantine/core";
import { IconX, IconCheck } from "@tabler/icons-react";
import useStatusCheck from "../../../hooks/useStatusCheck";
import { DataContext } from "../../../context/DataContext";
const DigiLockerReturn = () => {
  const { settings, data } = useContext(DataContext);
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState("PENDING");
  const [loading, setLoading] = useState(true);

  useStatusCheck(true, false, "idmax");

  useEffect(() => {
    const fetchStatus = async () => {
      setLoading(true);

      data.state = searchParams.get("state");
      data.code = searchParams.get("code");
      data.isIDMaxContinue = true;
      data.provider = "DIGILOCKER";

      try {
        const response = await fetch(
          process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/idmax",
          {
            method: "POST",
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data_returned = await response.json();

        setStatus(data_returned.status);
      } catch (error) {
        console.error("Error fetching status:", error);
        setStatus("ERROR"); // or a more descriptive error message
      } finally {
        setLoading(false);
      }
    };

    fetchStatus();
  }, [searchParams]);

  return <LoadingOverlay visible />;
};

export default DigiLockerReturn;
