import React, { useState, useContext, useEffect } from "react";
import { Flex, Text, Button, Container } from "@mantine/core";
import Radar from "radar-sdk-js";
import "radar-sdk-js/dist/radar.css";
import Lottie from "react-lottie-player";
import locationAnimation from "../../../../assets/animation_location.json";
import { DataContext } from "../../../../context/DataContext";

export default function Location() {
  const { data, setSessionState } = useContext(DataContext);
  const [result, setResult] = useState("");
  const [radarRunning, setRadarRunning] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleButtonClick = async () => {
    setRadarRunning(true);
    try {
      setLoading(true);
      Radar.initialize("prj_test_pk_a7282dc480ef41809516cbbf88de77513c5bdf2a");
      Radar.setUserId(data.email);
      const res = await Radar.trackOnce();
      setResult(res);
    } catch (err) {
      console.log("err", err);
      setResult(err);
    }
    setRadarRunning(false);
  };

  useEffect(() => {
    if (result) {
      setTimeout(() => {
        setSessionState((prevState) => ({
          ...prevState,
          "idmatch/predict/location": "COMPLETED",
          status: "PENDING",
        }));
        setLoading(false);
      }, 2000);
    }
  }, [result]);

  if (loading) {
    return (
      <Text align="center" style={{ marginTop: 400, fontSize: 20 }}>
        Checking your location...
      </Text>
    );
  }

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container>
        <Flex
          gap="xs"
          justify="center"
          align="center"
          direction="column"
          wrap="wrap"
        >
          <Lottie
            animationData={locationAnimation}
            loop
            play
            style={{ height: 150 }}
          />
          <Text align="center" fz="lg" fw={600}>
            Location Check Required
          </Text>
          <Text c="dimmed" align="center">
            We need to verify your location to determine your eligibility.
          </Text>
          <Button mt="xl" onClick={handleButtonClick} loading={radarRunning}>
            Verify My Location
          </Button>
        </Flex>
      </Container>
    </div>
  );
}
