import React, { useState, useEffect, useContext } from "react";
import {
  LoadingOverlay,
  Box,
  Container,
  NativeSelect,
  Alert,
  Select,
  Text,
  TextInput,
  Button,
  Group,
  Stack,
  Center,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { DataContext } from "../../../context/DataContext";
import {
  IconShieldCheck,
  IconShieldCancel,
  IconPhoneCheck,
} from "@tabler/icons-react";
import useStatusCheck from "../../../hooks/useStatusCheck";
import DataCollectionForm from "../../../components/data-collection-form";

export default function AgeMatch() {
  const { text, data, settings } = useContext(DataContext);
  const currentPath = "agematch/process";
  const [formConfig, setFormConfig] = useState(null);
  const [reattempts, setReattempts] = useState(1);

  useEffect(() => {
    const initialFields = [
      {
        name: "fn",
        label: text?.general?.firstName || "First Name",
        initialValue: data?.fn || "",
        group: "name",
        validation: (value) =>
          value.length < 2
            ? text?.general?.firstNameError || "First name is too short"
            : null,
      },
      {
        name: "ln",
        label: text?.general?.lastName || "Last Name",
        initialValue: data?.ln || "",
        group: "name",
        validation: (value) =>
          value.length < 2
            ? text?.general?.lastNameError || "Last name is too short"
            : null,
      },
    ];

    let additionalFields = [];

    if (data?.journey?.route) {
      const matchedRoute = settings.journey.route.find(
        (route) => route.path === currentPath
      );
      if (matchedRoute) {
        setReattempts(matchedRoute.reattempts || 1);
        const inputs = matchedRoute.inputs || [];
        additionalFields = inputs
          .map((input) => {
            switch (input) {
              case "address":
                return {
                  name: "address",
                  label: text?.general?.address || "Address",
                  type: "address",
                  validation: (value) => {
                    let addrError = "Address is required";

                    if (
                      value.addr &&
                      value.city &&
                      value.state &&
                      value.zip.length === 5
                    ) {
                      // The date is valid.
                      addrError = null;
                    }

                    return addrError;
                  },
                };
              case "dob":
                return {
                  name: "dob",
                  label: text?.general?.DateOfBirth || "Date of Birth",
                  type: "date",
                  //initialValue: data?.dob || "",
                  //group: "personal",
                  validation: (value) => {
                    const dobPattern =
                      /^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/;
                    const isDobFormatCorrect = dobPattern.test(value);

                    let dobError =
                      text?.general?.DateOfBirthError ||
                      "Invalid Date of Birth";

                    if (isDobFormatCorrect) {
                      const year = parseInt(value.substring(0, 4), 10);
                      const month = parseInt(value.substring(4, 6), 10) - 1; // JS months are 0-indexed
                      const day = parseInt(value.substring(6, 8), 10);

                      const date = new Date(year, month, day);

                      if (
                        date.getFullYear() === year &&
                        date.getMonth() === month &&
                        date.getDate() === day
                      ) {
                        dobError = null;
                      }
                    }
                    return dobError;
                  },
                };
              default:
                return null;
            }
          })
          .filter(Boolean); // Remove null values
      }
    }

    setFormConfig({ fields: [...initialFields, ...additionalFields] });
  }, [data, currentPath, text]);

  if (!formConfig) {
    return <LoadingOverlay visible />;
  }

  return (
    <DataCollectionForm
      config={formConfig}
      title={"Verify your Age"}
      subtitle={
        "Please verify your age by entering or confirming your legal name and accurate details for the best chance of success."
      }
      buttonText={"Verify My Age"}
      icon={
        <IconShieldCheck
          size={40}
          stroke={1.5}
          //color={data.styles?.primaryColor ? data.styles.primaryColor : "teal"}
        />
      }
      componentKey={currentPath}
      endpoint="/v3/stable/api/frontend/agematch"
      reAttempts={reattempts}
    />
  );
}
