import React, { useState, useEffect, useContext } from "react";
import { DataContext } from "../../context/DataContext";
import {
  Flex,
  Text,
  Group,
  Button,
  Container,
  Stack,
  Paper,
  Divider,
  Center,
  Loader,
} from "@mantine/core";
import QRCode from "react-qr-code";
import { IconDeviceMobileBolt } from "@tabler/icons-react";
import useStatusCheck from "../../hooks/useStatusCheck";
import { useNavigate } from "react-router-dom";
export default function Handoff({ nextPath, urlOverride }) {
  const { settings, setSessionState, dispatch, text } = useContext(DataContext);
  const [url, setUrl] = useState("");
  const [qrFound, setQrFound] = useState(false);
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [status, setStatus] = useState("NEW");
  const [shouldGetQrStatus, setShouldGetQrStatus] = useState(true);
  const navigate = useNavigate();
  useStatusCheck(qrFound, true, "handoff");
  useEffect(() => {
    const isLocalhost =
      window.location.hostname === "localhost" ||
      window.location.hostname.startsWith("127.0.0.") ||
      window.location.hostname.startsWith("0.0.0.0");

    if (isLocalhost) {
      if (urlOverride) {
        setUrl(urlOverride);
      } else {
        setUrl(
          `https://844198ea0184.ngrok.app/${settings.request_id}?qr=true&nextPath=${nextPath}`
        );
      }
    } else {
      const urlToUse =
        window.location.hostname === "vx-fe.dcams.app"
          ? `https://vx-fe.dcams.app/${settings.request_id}?qr=true&nextPath=${nextPath}`
          : `${process.env.REACT_APP_FRONTEND_URL}/${settings.request_id}?qr=true&nextPath=${nextPath}`;
      setUrl(urlToUse);
    }
  }, [settings, nextPath, urlOverride]);

  useEffect(() => {
    const fetchQrStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v3/stable/api/frontend/qrStatus`,
          {
            method: "POST",
            body: JSON.stringify({
              request_id: settings.request_id,
              type: "get",
              site_name: settings.site_name,
            }),
          }
        );
        const data = await response.json();

        if (data.found) {
          setQrFound(true); // Stop fetching once QR is found
        }
      } catch (error) {
        console.error("Error fetching QR status:", error);
      }
    };

    if (!qrFound) {
      const intervalId = setInterval(fetchQrStatus, 2000);
      return () => clearInterval(intervalId);
    }
  }, [qrFound, settings]);

  useEffect(() => {
    const timerId = setTimeout(
      () => {
        setIsTimeUp(true);
      },
      5 * 60 * 1000
    ); // 5 minutes

    return () => clearTimeout(timerId);
  }, []);

  const restartFetch = () => {
    setIsTimeUp(false);
    setShouldFetch(true);
    setShouldGetQrStatus(true); // Reset the flag to allow QR status fetching
  };

  if (qrFound) {
    return (
      <Stack
        align="center"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center", // This will push the button to the bottom
          height: "100%", // This should take the full height of its parent
        }}
      >
        <Loader />
        <Stack spacing={0} align="center">
          <Text fz="xl" fw={700} align="center">
            {text.handoff.inProgress.title}
          </Text>
          <Text c="dimmed" align="center">
            {text.handoff.inProgress.subTitle}
          </Text>
        </Stack>
        <Group noWrap>
          {isTimeUp && (
            <Button variant="outline" onClick={restartFetch}>
              {text.handoff.inProgress.button}
            </Button>
          )}
        </Group>
      </Stack>
    );
  }

  return (
    <Stack
      align="center"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", // This will push the button to the bottom
        height: "100%", // This should take the full height of its parent
      }}
    >
      <Stack align="center" mb={20}>
        <IconDeviceMobileBolt size={60} stroke={1} color="green" />
        <Text fz={25} fw={700}>
          {text.handoff.title}
        </Text>
      </Stack>

      <QRCode size={200} value={url} viewBox={`0 0 256 256`} />
      <Stack spacing={0} mt={20} p="sm">
        <Text align="center" fw={600}>
          {text.handoff.subTitle}
        </Text>
        <Text c="dimmed" fz="sm" align="center">
          {text.handoff.explanation}
        </Text>
      </Stack>
      {settings.journey?.settings?.allowUpload && (
        <Button
          mt="lg"
          variant="subtle"
          size="xs"
          onClick={() => navigate("/upload")}
        >
          I would rather continue on this device
        </Button>
      )}

      <Group noWrap>
        {isTimeUp && (
          <Button variant="outline" onClick={restartFetch}>
            {text.handoff.inProgress.button}
          </Button>
        )}
      </Group>
    </Stack>
  );
}
