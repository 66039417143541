import React, { useContext, useState } from "react";
import {
  Center,
  Stack,
  Text,
  Button,
  FileInput,
  Box,
  Group,
  ThemeIcon,
} from "@mantine/core";
import { DataContext } from "../../context/DataContext";
import { uploadImageToS3 } from "../../helpers";
import useStatusCheck from "../../hooks/useStatusCheck";
import CenterLoadingOverlay from "../../components/center-loading-overlay";
import Lottie from "react-lottie-player";
import IDScan from "../../assets/id-scan-loading.json";
import { IconId } from "@tabler/icons-react";
export default function Upload() {
  const { data, settings, text: textFromApi } = useContext(DataContext);
  const [frontValue, setFrontValue] = useState(null);
  const [backValue, setBackValue] = useState(null);
  const [uploadingPassport, setUploadingPassport] = useState(false);
  const [frontUploaded, setFrontUploaded] = useState(false);
  const [backUploaded, setBackUploaded] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const text = textFromApi.upload;
  const [uploading, setUploading] = useState(false);

  useStatusCheck(
    uploadingPassport ? frontUploaded : frontUploaded || backUploaded,
    false,
    "dcams/upload"
  );

  async function processAndUpload(suffix, file, setUploaded) {
    if (file) {
      setUploading(true);
      const blob = await file.arrayBuffer();
      await uploadImageToS3(`${settings.request_id}_dl_${suffix}.jpeg`, blob);
      setUploaded(true);
      setUploading(false);
    }
  }

  async function handleSubmit() {
    setIsVerifying(true);
    data.settings = settings;
    data.type = "Direct_Upload";
    fetch("https://vx.dcams.app/frontend/dcams/process", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (result) {
        //setIsVerifying(false);
      })
      .catch(function () {
        alert("Something Went Wrong! Please try again.");
      });
  }

  if (isVerifying) {
    return (
      <CenterLoadingOverlay
        content={
          <>
            <Stack spacing={5} style={{ marginTop: "-40px" }}>
              <Lottie
                animationData={IDScan}
                loop={true}
                play
                style={{ height: 200 }}
              />
              <Text c="dimmed" align="center">
                Checking your document...
              </Text>
            </Stack>
          </>
        }
      />
    );
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between", // This will push the button to the bottom
        height: "100%", // This should take the full height of its parent
      }}
    >
      <Box>
        <Stack>
          <Group>
            <ThemeIcon size={40} variant="light" radius="xl">
              <IconId size={35} stroke={1} />
            </ThemeIcon>
            <Text fz="xl" fw={700}>
              {text.title} {uploadingPassport ? text.passport : text.id}
            </Text>
          </Group>

          <FileInput
            name="front"
            placeholder="Please select a file"
            accept="image/png,image/jpeg"
            value={frontValue}
            onChange={(file) => {
              setFrontValue(file);
              processAndUpload("front", file, setFrontUploaded);
            }}
            label={
              uploading
                ? "Uploading..."
                : uploadingPassport
                  ? text.frontUploadPassportTitle
                  : text.frontUploadTitle
            }
          />
          {!uploadingPassport && (
            <FileInput
              name="back"
              placeholder="Please select a file"
              accept="image/png,image/jpeg"
              value={backValue}
              onChange={(file) => {
                setBackValue(file);
                processAndUpload("back", file, setBackUploaded);
              }}
              label={uploading ? "Uploading..." : text.backUploadTitle}
            />
          )}
        </Stack>
      </Box>
      <Box>
        <Stack>
          <Button
            variant="subtle"
            onClick={() => setUploadingPassport(!uploadingPassport)}
          >
            {uploadingPassport
              ? "I want to upload a License or ID"
              : "I want to upload a passport"}
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={
              uploadingPassport
                ? !frontUploaded
                : !frontUploaded || !backUploaded
            }
          >
            Submit
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
