import AWS from 'aws-sdk'

export const uploadImageToS3 = async (imageKey, imageFile) => {

    const bucketName = "dcamsv3"
    const bucketRegion = "us-east-2"
    const IdentityPoolId = "us-east-2:d6381cdb-1612-4dd1-ad4a-c8b28de1bd8b"
    const albumName = "upload";
    
    AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: IdentityPoolId
        })
    })

    // Initialize AWS S3 client
    const s3 = new AWS.S3({
        region: bucketRegion
    })

    var albumPhotosKey = encodeURIComponent(albumName) + "/";
  
    var photoKey = albumPhotosKey + imageKey;

    // Set up parameters for the S3 upload
    const params = {
        Bucket: bucketName,
        Key: photoKey,
        Body: imageFile,
        ContentType: 'image/jpeg' // change this based on your image type
    }

    // Use S3 upload method, wrapped in a Promise
    return new Promise((resolve, reject) => {
        s3.upload(params, (error, data) => {
            if (error) {
                reject(error)
            } else {
                resolve(data)
            }
        })
    })
}
