import React, { useState, useContext, useEffect } from "react";
import {
  LoadingOverlay,
  Text,
  Button,
  Group as MantineGroup,
  Stack,
  Center,
  ThemeIcon,
  Box,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconShieldCheck } from "@tabler/icons-react";

import { DataContext } from "../context/DataContext";
import useStatusCheck from "../hooks/useStatusCheck";
import useAdjustInputSize from "../hooks/useAdjustInputSize";
import FloatingLabelInput from "./floating-label-input";
import DateOfBirthSelect from "./date-of-birth-select";
import AddressInput from "./address-input";

const DataCollectionForm = ({
  title,
  subtitle,
  icon,
  componentKey,
  endpoint,
  config,
  buttonText,
  reAttempts = 0,
  onApiResponse,
}) => {
  const { data, text } = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(false);
  const [attemptsLeft, setAttemptsLeft] = useState(reAttempts);
  const inputSize = useAdjustInputSize();

  useStatusCheck(result, false, componentKey);

  const form = useForm({
    initialValues: config.fields.reduce((acc, field) => {
      if (field.type === "address") {
        acc["addr"] = data.addr || "";
        acc["city"] = data.city || "";
        acc["state"] = data.state || "";
        acc["zip"] = data.zip || "";
      } else {
        acc[field.name] = data[field.name] || field.initialValue || "";
      }
      return acc;
    }, {}),
    validate: (values) =>
      config.fields.reduce((acc, field) => {
        //console.log("values are", values);
        if (field.validation) {
          let error;

          // Check if the field is "address" and validate each part of the address
          if (field.name === "address" && field.type === "address") {
            // Validate each part of the address

            //console.log("there is an addr", values.addr);

            const addressForValidate = {
              addr: values.addr,
              city: values.city,
              state: values.state,
              zip: values.zip,
            };
            error = field.validation(addressForValidate);
            if (error) acc.address = error;
            /** 
            if (values.city) {
              error = field.validation(values.city);
              if (error) acc.city = error;
            }
            if (values.state) {
              error = field.validation(values.state);
              if (error) acc.state = error;
            }
            if (values.zip) {
              error = field.validation(values.zip);
              if (error) acc.zip = error;
            }
              */
          } else {
            // Validate other fields
            error = field.validation(values[field.name]);
            if (error) {
              acc[field.name] = error;
            }
          }
        }
        return acc;
      }, {}),
  });

  const submitHandler = async () => {
    const errors = form.validate();
    data.attemptsLeft = attemptsLeft;
    if (!errors.hasErrors) {
      setLoading(true);

      Object.keys(form.values).forEach((key) => {
        data[key] = form.values[key];
      });

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
          {
            method: "POST",
            body: JSON.stringify(data),
          }
        )
          .then((res) => res.json())
          .then((responseData) => {
            if (typeof onApiResponse === "function") {
              onApiResponse(responseData);
            }
            if (responseData.action === "PASS") {
              setLoading(true);
              setResult(true);
            } else {
              setAttemptsLeft(attemptsLeft - 1);
              if (attemptsLeft >= 1) {
                setResult(false);
                setLoading(false);
                form.resetDirty();
              } else {
                setLoading(true);
                setResult(true);
              }
            }
          });
      } catch (error) {
        alert("Something Went Wrong! Please try again.");
      }
    }
  };

  useEffect(() => {
    if (attemptsLeft < reAttempts) {
      const errors = form.validate();
      const allFieldsInvalid = Object.keys(form.values).reduce((acc, key) => {
        if (key === "fn") {
          acc[key] = text.coppa.secondAttemptFirstNameError;
        }
        if (key === "ln") {
          acc[key] = text.coppa.secondAttemptLastNameError;
        }
        return acc;
      }, {});
      form.setErrors(allFieldsInvalid);
    }
  }, [attemptsLeft, reAttempts]);

  const handleDobChange = (dob) => {
    form.setFieldValue("dob", dob.year + dob.month + dob.day);
  };

  const handleAddressChange = (address) => {
    form.setFieldValue("addr", address.addr);
    form.setFieldValue("city", address.city);
    form.setFieldValue("state", address.state);
    form.setFieldValue("zip", address.zip);
  };

  useEffect(() => {
    const errors = form.validate();
    if (!errors.hasErrors) {
      submitHandler();
    } else {
      setLoading(false);
      form.clearErrors();
    }
  }, []);

  if (loading) {
    return <LoadingOverlay visible />;
  }

  const groupedFields = config.fields.reduce(
    (acc, field) => {
      if (field.group) {
        if (!acc.grouped[field.group]) {
          acc.grouped[field.group] = [];
        }
        acc.grouped[field.group].push(field);
      } else {
        acc.ungrouped.push(field);
      }
      return acc;
    },
    { grouped: {}, ungrouped: [] }
  );

  return (
    <form
      onSubmit={form.onSubmit(submitHandler)}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Box>
        <Stack spacing={8} mt={inputSize === "md" ? 5 : 5}>
          <Center>
            <ThemeIcon
              variant="light"
              c={data.styles?.primaryColor ? data.styles.primaryColor : "teal"}
              size={inputSize === "md" ? 60 : 55}
              radius="xl"
            >
              {icon}
            </ThemeIcon>
          </Center>
          <Stack spacing={0} mt="sm" mb="lg">
            <Text fz={inputSize === "md" ? 24 : 20} fw={700}>
              {title}
            </Text>
            <Text c="dimmed" fz={inputSize}>
              {subtitle}
            </Text>
          </Stack>
        </Stack>
        <Stack spacing={10}>
          {Object.keys(groupedFields.grouped).map((group) => (
            <MantineGroup key={group} spacing="md" noWrap grow>
              {groupedFields.grouped[group].map((field) => {
                if (field.type === "date") {
                  return (
                    <DateOfBirthSelect
                      key={field.name}
                      onDobChange={handleDobChange}
                      {...form.getInputProps(field.name)}
                    />
                  );
                } else if (field.type === "address") {
                  return (
                    <AddressInput
                      key={field.name}
                      onAddressChange={handleAddressChange}
                      value={{
                        addr: form.values.addr,
                        city: form.values.city,
                        state: form.values.state,
                        zip: form.values.zip,
                      }}
                      {...form.getInputProps(field.name)}
                    />
                  );
                } else {
                  return (
                    <FloatingLabelInput
                      key={field.name}
                      size="lg"
                      label={field.label}
                      isPassword={field.isPassword}
                      {...form.getInputProps(field.name)}
                    />
                  );
                }
              })}
            </MantineGroup>
          ))}
          {groupedFields.ungrouped.map((field) => {
            if (field.type === "date") {
              return (
                <DateOfBirthSelect
                  key={field.name}
                  onDobChange={handleDobChange}
                  {...form.getInputProps(field.name)}
                />
              );
            } else if (field.type === "address") {
              return (
                <AddressInput
                  key={field.name}
                  onAddressChange={handleAddressChange}
                  value={{
                    addr: form.values.addr,
                    city: form.values.city,
                    state: form.values.state,
                    zip: form.values.zip,
                  }}
                  {...form.getInputProps(field.name)}
                />
              );
            } else {
              return (
                <FloatingLabelInput
                  key={field.name}
                  size="lg"
                  label={field.label}
                  isPassword={field.isPassword}
                  {...form.getInputProps(field.name)}
                />
              );
            }
          })}
        </Stack>
      </Box>
      <Box pb="sm">
        <Button mt="xl" fullWidth type="submit" size="lg">
          {buttonText}
        </Button>
      </Box>
    </form>
  );
};

export default DataCollectionForm;
