// src/RootComponent.js
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import App from "./App";
import { DataProvider } from "./context/DataContext";
import NotFound from "./pages/not-found";
import SubRoutes from "./routes";
import {
  FpjsProvider,
  FingerprintJSPro,
} from "@fingerprintjs/fingerprintjs-pro-react";
import * as Sentry from "@sentry/react"; // Import Sentry

// Sentry wrapped Routes
///const { request_id } = useParams();
Sentry.init({
  dsn: "https://e5bbbf0db439485e92518f5484e1baae@o4504792099258368.ingest.us.sentry.io/4504792101158912",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.breadcrumbsIntegration({
      console: true,
      dom: true,
      fetch: true,
      history: true,
      xhr: true,
    }),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration(),
  ],
  tracesSampleRate: 1.0, // Adjust based on your need
  tracePropagationTargets: [
    /^https:\/\/fe\.dcams\.app/, // Match fe.dcams.app
    /^https:\/\/vx-fe\.dcams\.app/, // Match vx-fe.dcams.app
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppWithData = () => {
  const { request_id } = useParams();

  useEffect(() => {
    if (request_id) {
      // Set request_id as a tag in Sentry
      Sentry.setTag("request_id", request_id);
    }
  }, [request_id]);

  return (
    <FpjsProvider
      loadOptions={{
        apiKey: "U1OoU5FUoWuUW1gJvyL3",
        endpoint: [
          "https://fingerprint.dcams.app",
          FingerprintJSPro.defaultEndpoint,
        ],
        scriptUrlPattern: [
          "https://fingerprint.dcams.app/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
          FingerprintJSPro.defaultScriptUrlPattern,
        ],
      }}
    >
      <DataProvider request_id={request_id}>
        <App routes={<SubRoutes />} />
      </DataProvider>
    </FpjsProvider>
  );
};

const RootComponent = () => {
  return (
    <Router>
      <Sentry.ErrorBoundary fallback={<div>An error has occurred.</div>}>
        <SentryRoutes>
          <Route path="/:request_id/*" element={<AppWithData />} />
          <Route path="*" element={<NotFound />} />
        </SentryRoutes>
      </Sentry.ErrorBoundary>
    </Router>
  );
};

export default RootComponent;
