import React from "react";
import { Paper, Text, Flex, Container } from "@mantine/core";
import { IconAlertCircle, IconShieldCheck } from "@tabler/icons-react";
import SubRoutes from "../routes";

export default function ErrorMessage({ title, subtitle }) {
  const icon =
    title === "Verification Success" ? (
      <IconShieldCheck size={80} stroke={1} color="green" />
    ) : (
      <IconAlertCircle size={80} stroke={1} color="red" />
    );
  return (
    <Container>
      <Flex style={{ height: "500px" }} align="center" justify="center">
        <Paper padding="md" align="center">
          {icon}
          <Text fz={35} fw={700}>
            {title}
          </Text>
          <Text mt="md" fz={16} c="dimmed">
            {subtitle}
          </Text>
        </Paper>
      </Flex>
    </Container>
  );
}
