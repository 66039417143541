import React, { useContext, useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Center,
  Image,
  Stack,
  Text,
  CopyButton,
  Paper,
  RingProgress,
  Loader,
  Group,
  MediaQuery,
  Timeline,
} from "@mantine/core";
import { DataContext } from "../../../context/DataContext";
import { IconCopy, IconCheck, IconX } from "@tabler/icons-react";
import { isMobile } from "react-device-detect";
import LaWalletImage from "../../../assets/LA_Wallet.jpeg";
import useStatusCheck from "../../../hooks/useStatusCheck";
export default function LAWallet() {
  const { settings, data } = useContext(DataContext);
  const totalTime = 5 * 60;
  const [timeLeft, setTimeLeft] = useState(totalTime);
  const [code, setCode] = useState(<Loader />);
  const [canRefresh, setCanRefresh] = useState(true);
  const [status, setStatus] = useState("PENDING");
  const [checkStatus, setCheckStatus] = useState(false);

  useStatusCheck(checkStatus, false, "idmax");

  const fetchCode = useCallback(async () => {
    data.provider = "LAWallet";
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/idmax",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      const data_returned = await response.json();
      setCode(data_returned.output.info.code);
      setCheckStatus(true);
      setTimeLeft(totalTime); // Reset the timer to 5 minutes
    } catch (error) {
      console.error("Error fetching code:", error);
    }
  }, [settings.request_id]);

  useEffect(() => {
    fetchCode();
  }, [fetchCode]);

  useEffect(() => {
    let interval;
    if (timeLeft != null) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timeLeft]);

  const handleRefreshClick = () => {
    if (canRefresh) {
      fetchCode();
      setCanRefresh(false); // Prevent further refreshes
    }
  };

  const timerDisplay =
    timeLeft > 0 ? (
      <Text size="sm">
        {Math.floor(timeLeft / 60)}:
        {timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
      </Text>
    ) : canRefresh ? (
      <Text size="sm">00:00</Text>
    ) : (
      <Text size="sm">00:00</Text>
    );

  if (status !== "PENDING") {
    return (
      <>
        <Stack mt="xl">
          <Group noWrap>
            {status === "PASS" ? (
              <IconCheck color="green" size={50} />
            ) : (
              <IconX color="red" size={50} />
            )}

            <Text fz="xl" fw={700}>
              {status === "PASS"
                ? "You have been succesfully verified."
                : "We were unable to verify you"}
            </Text>
          </Group>
          <Group mt="xl">
            <Loader />
            <Text>Completing the process...</Text>
          </Group>
        </Stack>
      </>
    );
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between", // This will push the button to the bottom
        height: "100%", // This should take the full height of its parent
      }}
    >
      <Box>
        <Stack>
          <Stack spacing={0}>
            <Text c="dimmed" fz="md">
              Verify with
            </Text>
            <Group spacing={6}>
              <Image src={LaWalletImage} width={35} />
              <Text fz="xl">LA Wallet</Text>
            </Group>
          </Stack>

          <Text fz="md" fw={700}>
            How Verification with LA Wallet Works
          </Text>
          <Paper mt={0} withBorder p="sm" style={{ textAlign: "center" }}>
            <Timeline radius="md" active={4} lineWidth={3} bulletSize={16}>
              <Timeline.Item
                bullet={<IconCheck size={12} />}
                title={<Text fz="sm">Open your LA Wallet App</Text>}
              ></Timeline.Item>
              <Timeline.Item
                bullet={<IconCheck size={12} />}
                title={<Text fz="sm">Tap "Share" at the bottom</Text>}
              ></Timeline.Item>
              <Timeline.Item
                bullet={<IconCheck size={12} />}
                title={<Text fz="sm">Tap "Remote Verify" at the top</Text>}
              ></Timeline.Item>
              <Timeline.Item
                bullet={<IconCheck size={12} />}
                title={<Text fz="sm">Enter the code shown below</Text>}
              ></Timeline.Item>
            </Timeline>
          </Paper>
          <Paper
            mt={0}
            withBorder
            p="md"
            style={{ backgroundColor: "#f0eded", textAlign: "center" }}
          >
            {timeLeft > 0 ? (
              <Stack spacing="sm">
                <Text fz={60} fw={700}>
                  {code}
                </Text>
                <Text fz="sm" c="dimmed">
                  Pleae enter this code into your LA Wallet App
                </Text>
                {isMobile && (
                  <CopyButton value={code} timeout={2000}>
                    {({ copied, copy }) => (
                      <Button
                        //fullWidth
                        style={{ alignSelf: "center" }}
                        compact
                        size="md"
                        leftIcon={
                          copied ? (
                            <IconCheck size="1rem" stroke={1} />
                          ) : (
                            <IconCopy size="1rem" stroke={1} />
                          )
                        }
                        onClick={copy}
                      >
                        {copied ? "CODE COPIED" : "COPY CODE"}
                      </Button>
                    )}
                  </CopyButton>
                )}
              </Stack>
            ) : (
              <>
                {canRefresh ? (
                  <Stack>
                    <Text>Code is expired</Text>
                    <Button onClick={handleRefreshClick}>Get New Code</Button>
                  </Stack>
                ) : (
                  <Text>
                    You have used all of your codes. Please go back and try a
                    different method.
                  </Text>
                )}
              </>
            )}
          </Paper>
        </Stack>
      </Box>
      <Box>
        <Stack position="center" spacing={0}>
          <Center>
            <RingProgress
              size={60}
              thickness={5}
              roundCaps
              sections={[
                {
                  value: timeLeft != null ? (timeLeft / (5 * 60)) * 100 : 0,
                  color:
                    timeLeft != null
                      ? timeLeft > totalTime * 0.5
                        ? "blue"
                        : timeLeft > totalTime * 0.2
                          ? "orange"
                          : "red"
                      : "gray",
                },
              ]}
              label={<Center>{timerDisplay}</Center>}
            />
          </Center>
          <Text ta="center" c="dimmed" fz="sm">
            This code will expire after 5 minutes
          </Text>
        </Stack>
      </Box>

      {/*
        <Box
          sx={(theme) => ({
            display: "none",
            "@media (max-width: 40em)": {
              display: "inline-block",
            },
          })}
        >
        
        <CopyButton value={code} timeout={2000}>
          {({ copied, copy }) => (
            <Button
              fullWidth
              style={{ alignSelf: "center" }}
              size="lg"
              leftIcon={
                copied ? (
                  <IconCheck size="2rem" stroke={1} />
                ) : (
                  <IconCopy size="2rem" stroke={1} />
                )
              }
              onClick={copy}
            >
              {copied ? "CODE COPIED" : "COPY CODE"}
            </Button>
          )}
        </CopyButton>
        
        </Box>
         */}
    </Box>
  );
}
