import React, { useEffect, useRef, useContext, useState } from "react";
import { DataContext } from "../../../context/DataContext";
import { Center } from "@mantine/core";

const YotiButton = ({ onReady }) => {
  const { data, settings, setSessionState } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const buttonRef = useRef(null);
  const scriptLoaded = useRef(false);

  useEffect(() => {
    if (scriptLoaded.current) {
      return;
    }

    const script = document.createElement("script");
    script.src = "https://www.yoti.com/share/client/";
    script.onload = () => {
      if (window.Yoti && window.Yoti.Share) {
        const yoti = window.Yoti.Share.init({
          elements: [
            {
              domId: buttonRef.current.id,
              scenarioId: "9520b486-6386-49a8-8011-f488fc79ba7b",
              clientSdkId: "8b7af083-1a12-4c85-b333-1b6225e9b18e",
              type: "inline",
              displayLearnMoreLink: false,
              shareComplete: {
                mobileFlow: "internal",
                tokenHandler: (token, done) => {
                  fetch("http://localhost:8000/src/yoti.php", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: `token=${token}`,
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      done();
                      setSessionState((prevState) => ({
                        ...prevState,
                        "yoti/id": "COMPLETED",
                        status: "PASS",
                      }));
                    })
                    .catch((error) => {
                      console.error("Error fetching data:", error);
                      done();
                    });
                },
              },
            },
          ],
        });
        scriptLoaded.current = true;
        setTimeout(() => {
          setIsLoading(false);
          onReady && onReady();
        }, 2000);
      }
    };
    document.head.appendChild(script);

    return () => {
      script.remove();
    };
  }, []);

  return <div ref={buttonRef} id="veratad_yoti_id"></div>;
};

export default YotiButton;
