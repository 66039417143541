import React, { useEffect, useContext } from "react";
import {
  Route,
  Routes,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import IDMatch from "./pages/idmatch/coppa";
import Dcams from "./pages/dcams";
import Location from "./pages/idmatch/predict/location";
import FaceCapture from "./pages/face-capture";
import AgeMatchModular from "./pages/agematch/modular";
import AgeEstimation from "./pages/agematch/estimation";
import YotiButton from "./pages/digital-id/yoti";
import YotiCallback from "./pages/digital-id/yoti/callback";
import Handoff from "./pages/handoff";
import NotFound from "./pages/not-found";
import { DataContext } from "./context/DataContext";
import DcamsModular from "./pages/dcams/modular";
import Clear from "./pages/dcams/clear";
import AgeMatch from "./pages/agematch/process";
import PhoneMatch from "./pages/phonematch";
import ErrorMessage from "./components/error-message";
import Plaid from "./pages/dcams/plaid/plaid-start";
import YotiID from "./pages/dcams/yoti";
import LAWallet from "./pages/dcams/la-wallet";
import OneIdStart from "./pages/dcams/one-id/start";
import OneIdReturn from "./pages/dcams/one-id/return";
import DIGILOCKERStart from "./pages/dcams/digilocker/start";
import DigiLockerReturn from "./pages/dcams/digilocker/return";
import IDMatchPlus from "./pages/idmatch/plus";
import Upload from "./pages/dcams/upload";
import { LayerPlacement } from "maplibre-gl";
const componentMap = {
  handoff: Handoff,
  upload: Upload,
  "idmatch/coppa": IDMatch,
  "agematch/modular": AgeMatchModular,
  "agematch/estimate": AgeEstimation,
  "agematch/process": AgeMatch,
  phonematch: PhoneMatch,
  "dcams/mitek": Dcams,
  "dcams/modular": DcamsModular,
  "face-capture/:sdk": FaceCapture,
  "idmatch/predict/location": Location,
  "yoti/id/callback": YotiCallback,
  "dcams/clear/clear-start": Clear,
  "dcams/plaid/plaid-start": Plaid,
  "dcams/yoti": YotiID,
  "dcams/la-wallet": LAWallet,
  "dcams/oneid": OneIdStart,
  "dcams/digilocker": DIGILOCKERStart,
  "digilocker/return/success": DigiLockerReturn,
  "digilocker/return/failure": DigiLockerReturn,
  "oneid/return": OneIdReturn,
  "clear/return": Clear,
  "idmatch/plus": IDMatchPlus,
};

const SubRoutes = () => {
  const { currentIndex, routeSequence, settings, selectedComponent } =
    useContext(DataContext);
  const location = useLocation();

  const isModular = settings.journey.settings?.isModular || false;

  // Log the current index and route sequence for debugging
  //console.log("Current index:", currentIndex);
  //console.log("Route sequence:", routeSequence);
  //console.log("Selected Component:", selectedComponent);

  // Get the current path from routeSequence using currentIndex
  /*
  const currentPath =
    isModular && routeSequence.length > 1
      ? selectedComponent
      : routeSequence[currentIndex]?.path;
      */
  const currentPath = routeSequence[currentIndex]?.path;
  // console.log("Current path:", currentPath);

  if (location.pathname.includes("/upload")) {
    return (
      <Routes>
        <Route path="*" element={<Upload />} />
      </Routes>
    );
  }

  if (location.pathname.includes("/clear/return")) {
    //console.log("Navigating to Clear Return");
    return (
      <Routes>
        <Route path="*" element={<Clear />} />
      </Routes>
    );
  }

  if (location.pathname.includes("/oneid/return")) {
    //console.log("Navigating to OneId Return");
    return (
      <Routes>
        <Route path="*" element={<OneIdReturn />} />
      </Routes>
    );
  }

  if (location.pathname.includes("/digilocker/return")) {
    //console.log("Navigating to DigiLocker Return");
    return (
      <Routes>
        <Route path="*" element={<DigiLockerReturn />} />
      </Routes>
    );
  }

  if (isModular && !currentPath && routeSequence.length > 1) {
    //console.log("it is modular");
    //return <AgeMatchModular />;
  }

  // Find the corresponding component from the mapping
  const CurrentComponent = componentMap[currentPath] || NotFound;
  //console.log("Current Component:", CurrentComponent.name);

  return (
    <Routes>
      <Route path="*" element={<CurrentComponent />} />
    </Routes>
  );
};

export default SubRoutes;
