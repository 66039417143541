import React, { useState } from "react";
import {
  TextInput,
  Box,
  Text,
  Tooltip,
  useMantineTheme,
  Input,
  PasswordInput,
  rem,
} from "@mantine/core";
import { IconLock } from "@tabler/icons-react";

const FloatingLabelInput = ({
  label,
  tooltip,
  onChange,
  value: propValue,
  customOnChange,
  component,
  isPassword = false,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const theme = useMantineTheme();

  // Determine if the label should float
  const floating = focused || (propValue && propValue.length > 0);

  const handleChange = (event) => {
    // Run custom logic if provided
    if (customOnChange) {
      customOnChange(event);
    } else {
      // Otherwise, just use the provided onChange
      onChange && onChange(event);
    }
  };

  const InputComponent = isPassword ? PasswordInput : TextInput;

  const InputComponentReturn = (
    <InputComponent
      {...props}
      size={"lg"}
      value={propValue}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onChange={handleChange}
      label={label}
      placeholder={floating ? props.placeholder : ""}
      autoComplete="off"
      styles={(theme) => ({
        input: {
          fontSize: "18px",
          paddingTop: "15px",
        },
        innerInput: {
          fontSize: "18px",
          paddingTop: "15px",
        },
        label: {
          position: "absolute",
          pointerEvents: "none",
          fontSize: "11px",
          paddingLeft: "10px",
          paddingTop: "5px",
          zIndex: "1",
          color: "#868e96",
        },
        root: {
          position: "relative",
        },
        error: {
          fontSize: "12px",
        },
      })}
    />
  );

  return InputComponentReturn;
};

export default FloatingLabelInput;
