import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const YotiCallback = () => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (token) {
      fetch("https://your-api-endpoint-here.com", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response data
          console.log(data);
        })
        .catch((error) => {
          // Handle any errors
          console.error(error);
        });
    }
  }, [location.search]);

  return <div>Processing Yoti callback...</div>;
};

export default YotiCallback;
