import React, { useEffect, useContext, useState } from "react";
import {
  MantineProvider,
  Box,
  Container,
  Paper,
  Image,
  Center,
  Stack,
  Text,
  Group,
  Divider,
  Anchor,
  LoadingOverlay,
} from "@mantine/core";

import { Notifications } from "@mantine/notifications";
import VXLogo from "./assets/vx-logo.svg";
import { DataContext } from "./context/DataContext";
import Navigation from "./components/navigation";
import { isMobile } from "react-device-detect";
const isIframe = window !== window.top;
import "./App.css";

function App({ routes }) {
  const { text } = useContext(DataContext);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  // Update the viewport height on resize
  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Inline styles for .content div
  const contentStyles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: `calc(${viewportHeight}px - 50px)`, // Dynamic height based on the state
    padding: "0 25px",
    paddingTop: "10px",
  };

  const desktopContainerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#fafafa",
    "@media (max-width: 40em)": {
      backgroundColor: "red",
    },
  };

  const contentStylesDirectDesktop = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "400px",
    border: ".5px solid #dad9d9",
    padding: "25px 25px",
    paddingTop: "10px",
    //boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
    "@media (max-width: 40em)": {
      width: "100%",
      border: "none",
    },
  };

  const { data } = useContext(DataContext); // Destructure to get theme
  if (!data) {
    return <LoadingOverlay visible />;
  }

  const colors_array = data.styles?.colors
    ? data.styles.colors
    : {
        "vx-green": [
          "#eff6fb",
          "#dee9f1",
          "#b8d2e5",
          "#8fbad8",
          "#6ea5ce",
          "#5998c8",
          "#4d92c6",
          "#3e7eaf",
          "#34709d",
          "#22618b",
        ],
      };

  return (
    <MantineProvider
      theme={{
        //fontFamily: "Roboto, sans-serif",
        colors: colors_array,
        colorScheme: data.styles?.colorScheme
          ? data.styles.colorScheme
          : "light",
        primaryColor: data.styles?.primaryColor
          ? data.styles.primaryColor
          : "vx-green",
        primaryShade: data.styles?.primaryShade ? data.styles.primaryShade : 5,
        components: {
          Button: {
            defaultProps: {
              radius: data.styles?.button?.radius
                ? data.styles.button.radius
                : 6,
            },
          },
        },
        defaultRadius: data.styles?.defaultRadius ? data.defaultRadius : 6,
        breakpoints: {
          xs: 340, // Extra small screen breakpoint in pixels
          sm: 640, // Small screen breakpoint in pixels
          md: 960, // Medium screen breakpoint in pixels
          lg: 1280, // Large screen breakpoint in pixels
          xl: 1440, // Extra large screen breakpoint in pixels
        },
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <Notifications position="top-center" zIndex={2077} />
      <div className={!isIframe && !isMobile ? "desktopContainer" : ""}>
        <div
          style={isMobile ? { height: `calc(${viewportHeight}px - 50px)` } : {}}
          className={
            !isIframe && !isMobile ? "contentStylesDirectDesktop" : "content"
          }
        >
          <Navigation logo_url={data?.logo_url ? data.logo_url : ""} />
          {routes}
        </div>
      </div>

      <Box
        align="center"
        style={{
          position: "absolute",
          bottom: 15,
          width: "100%",
          align: "center",
        }}
      >
        <Center>
          <Group spacing={10}>
            <Group spacing={5}>
              <Image src={VXLogo} width={20} />
              <Text fz="xs" c="dimmed">
                {text.general.poweredBy} Veratad
              </Text>
            </Group>
            <Divider orientation="vertical" />
            <Anchor
              href="https://veratad.com/privacy-policy/"
              size="xs"
              target="_blank"
            >
              {text.general.privacyPolicy}
            </Anchor>
          </Group>
        </Center>
      </Box>
    </MantineProvider>
  );
}

export default App;
