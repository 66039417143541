import React from "react";
import { Paper, Text, Flex, Container } from "@mantine/core";

export default function CenterLoadingOverlay({ content }) {
  return (
    <Container
      style={{
        height: "100vh",
        backgroundColor: "white",
        zIndex: 2000,
      }}
    >
      <Flex style={{ height: "100vh" }} align="center" justify="center">
        {content}
      </Flex>
    </Container>
  );
}
