import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Group,
  Stack,
  Text,
  Image,
  Box,
  Loader,
  Flex,
  Center,
  CopyButton,
  ActionIcon,
  Paper,
} from "@mantine/core";
import {
  IconBolt,
  IconHeart,
  IconLock,
  IconBuildingBank,
} from "@tabler/icons-react";

import { DataContext } from "../../../context/DataContext";
import { isMobile } from "react-device-detect";
import QRCode from "qrcode.react";
import useStatusCheck from "../../../hooks/useStatusCheck";

const DIGILOCKERStart = () => {
  const { settings, data } = useContext(DataContext);
  const [url, setUrl] = useState(null);
  //useStatusCheck(true, false, "idmax");

  const handleVerifyClick = () => {
    window.open(url, "_blank");
    //window.location.href = url;
  };

  useEffect(() => {
    if (url) return;
    const fetchUrl = async () => {
      // setLoading(true);

      data.provider = "DIGILOCKER";
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/idmax",
          {
            method: "POST",
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data_returned = await response.json();
        console.log("data", data_returned);
        setUrl(data_returned.output.info.request_url);
      } catch (error) {
        console.error("Error fetching status:", error);
        //setStatus("ERROR"); // or a more descriptive error message
      } finally {
        //setLoading(false);
      }
    };

    fetchUrl();
  }, [url]);

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // This will push the button to the bottom
          height: "100%", // This should take the full height of its parent
        }}
      >
        <Box>
          <Stack>
            <Stack align="center" spacing={0}>
              <Group spacing={6}>
                <Image
                  width={35}
                  src="https://dcamsclientlogos.s3.us-east-2.amazonaws.com/digilocker.webp"
                />
                <Text fz="xl" fw={700}>
                  Verify With DigiLocker
                </Text>
              </Group>

              <Text fz="sm" c="dimmed">
                Powered by Signzy
              </Text>
            </Stack>
            <Center mt="sm">
              <Paper p="lg" withBorder style={{ backgroundColor: "#fafafa" }}>
                <Stack>
                  <Group noWrap>
                    <IconBolt size={20} />
                    <Text fz="md">Instant verification</Text>
                  </Group>
                  <Group noWrap>
                    <IconLock size={20} />
                    <Text fz="md">Keep your data safe and secure</Text>
                  </Group>
                  <Group noWrap>
                    <IconHeart size={20} />
                    <Text fz="md">No need to provide documents</Text>
                  </Group>
                </Stack>
              </Paper>
            </Center>
          </Stack>
          {!isMobile && (
            <Stack align="center" mt="xl">
              <Box
                style={{
                  height: 200,
                  width: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack>
                  {url ? <QRCode value={url} size={200} /> : <Loader />}
                </Stack>
              </Box>

              <Stack spacing={5}>
                <Text ta="center" fw={700}>
                  Scan to continue
                </Text>
              </Stack>
            </Stack>
          )}
        </Box>
        <Box>
          {!isMobile ? (
            <Group position="center" mt="md" noWrap>
              <IconLock />
              <Text fz="xs">DIGILOCKER® is a Government.....</Text>
            </Group>
          ) : (
            <Button
              size="lg"
              mt="lg"
              //leftIcon={<Image src={DIGILOCKERIconWhite} width={24} />}
              style={{
                backgroundColor: "#041A55",
              }}
              onClick={handleVerifyClick}
              fullWidth
            >
              Get Started
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DIGILOCKERStart;
