import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Group,
  Center,
  Stack,
  Text,
  Container,
  rem,
  LoadingOverlay,
  Flex,
  ThemeIcon,
  Burger,
  Drawer,
  ActionIcon,
  Button,
  Switch,
  Divider,
  UnstyledButton,
  Image,
  Radio,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconCalendarBolt,
  IconPhone,
  IconCreditCard,
  IconFaceId,
  IconChevronRight,
  IconId,
  IconDeviceMobileStar,
  IconDotsVertical,
  IconDots,
  IconUser,
  IconHttpOptions,
  IconSettingsBolt,
  IconCapture,
  IconArrowLeftTail,
  IconArrowLeft,
  IconBolt,
  IconUpload,
  IconLock,
  IconHelp,
  IconQuestionMark,
  IconLifebuoy,
  IconChevronDown,
  IconCamera,
  IconCameraBolt,
} from "@tabler/icons-react";

import ClearIcon from "../../assets/clear_logo_white.svg";
import ClearLogo from "../../assets/clear_logo.png";
import MdlIcon from "../../assets/mdl.png";
import IDMeIcon from "../../assets/idme-logo.svg";
import LaWallet from "../../assets/LA_Wallet.png";
import LaWalletLogo from "../../assets/la-wallet-logo.png";
import PlaidIcon from "../../assets/plaid_icon_white.png";
import PlaidLogo from "../../assets/plaid_logo.png";
import PlaidLogoWhite from "../../assets/plaid_white_logo.png";
import IDCard from "../../assets/id-card.svg";
import IDMeButtonImage from "../../assets/id_me_white_logo-button.svg";
import Minimal from "../../assets/minimal.png";
import { DataContext } from "../../context/DataContext";
import IDMatch from "../idmatch/coppa";
import DigitalId from "../digital-id";
import IDMe from "./idme/Index";
import LAWallet from "./la-wallet";
import Clear from "./clear/index";
import Plaid from "./plaid/plaid-start";
import OneIdStart from "./one-id/start";
import YotiID from "./yoti";
import Handoff from "../handoff";
import Dcams from ".";
import useAdjustInputSize from "../../hooks/useAdjustInputSize";

export default function DcamsModular() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, settings, setSessionState } = useContext(DataContext);
  const [choices, setChoices] = useState([]);
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [choiceVisibility, setChoiceVisibility] = useState({});
  const [currentChoice, setCurrentChoice] = useState(null);
  const [hoveredChoice, setHoveredChoice] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);
  const inputSize = useAdjustInputSize();
  const getIconAndLabel = (choice) => {
    switch (choice) {
      case "plaid":
        return {
          button: (
            <Button
              //radius="xl"
              fullWidth
              size="lg"
              color="dark"
              //variant="outline"
              leftIcon={<Image src={PlaidIcon} width={35} />}
              style={{
                backgroundColor: "#000000",
                //fontColor: "#000000",
                //height: "56px",
                fontSize: "14px",
              }}
            >
              VERIFY WITH PLAID
            </Button>
          ),
          isButton: true,
          label: "Verify with PLAID",
        };
      case "idme_hold":
        return {
          icon: IDMeIcon,
          isButton: false,
          label: "Verify with ID.me",
          component: <IDMatch />,
        };
      case "photo":
        return {
          icon: <IconCapture stroke={2} size={40} />,
          isButton: true,
          button: (
            <>
              <Button
                //variant="light"
                //radius="xl"
                fullWidth
                size="lg"
                style={{ fontSize: "14px" }}
                //leftIcon={<IconCameraBolt stroke={1} size={30} />}
                //gradient={{ from: "indigo", to: "cyan", deg: 135 }}
              >
                VERIFY WITH ID IMAGE
              </Button>
            </>
          ),
          iconColor: "indigo",
          label: "Verify with ID Image",
          component: <Handoff nextPath={"test"} />,
        };
      case "clear_hold":
        return {
          icon: ClearIcon,
          iconColor: "indigo",
          label: "Verify with CLEAR",
        };
      case "clear":
        return {
          button: (
            <Button
              //radius="xl"
              fullWidth
              size="lg"
              //onClick={() => navigate("/dcams/clear/clear-start")}
              //color="dark"
              //variant="outline"
              leftIcon={<Image src={ClearIcon} width={25} />}
              style={{
                backgroundColor: "#041A55",
                //fontColor: "#000000",
                //height: "56px",
                fontSize: "14px",
              }}
            >
              VERIFY WITH CLEAR
            </Button>
          ),
          isButton: true,
          label: "Verify with CLEAR",
        }; // Adjust the icon as needed
      case "mdl":
        return {
          icon: LaWallet,
          iconColor: "grape",
          label: "Verify with LA Wallet",
          isButton: true,
          button: (
            <Button
              //radius="xl"
              fullWidth
              size="lg"
              //color="dark"
              //variant="outline"
              leftIcon={<Image src={LaWallet} width={25} />}
              style={{
                backgroundColor: "#244fa4",
                //fontColor: "#000000",
                //height: "56px",
                fontSize: "14px",
              }}
            >
              VERIFY WITH LA WALLET
            </Button>
          ),
        };
      case "id_doc_mobile":
        return {
          icon: <IconDeviceMobileStar stroke={2} />,
          iconColor: "teal",
          label: "Mobile Drivers License or ID Wallet",
          component: <DigitalId />,
        }; // Adjust the icon as needed
      default:
        return { icon: null, label: choice };
    }
  };

  useEffect(() => {
    const currentRoute = settings.journey.route.find(
      (route) => route.path === "dcams/modular"
    );
    if (currentRoute && currentRoute.choices) {
      let filteredChoices = currentRoute.choices;

      if (!(settings.country === "US" && settings.state === "LA")) {
        filteredChoices = filteredChoices.filter((choice) => choice !== "mdl");
      }

      setChoices(filteredChoices);
    }
  }, [data, settings]);

  useEffect(() => {
    if (choices.length) {
      const initialVisibility = {};
      choices.forEach((choice) => {
        if (choice !== "idme") {
          initialVisibility[choice] = true;
        }
        // by default, all choices are visible
      });
      setChoiceVisibility(initialVisibility);
    }
  }, [choices]);

  const handleChoiceClick = (choice) => {
    setCurrentChoice(choice);
  };

  const clearChoice = () => {
    setCurrentChoice(null);
  };

  const components = (
    <>
      {/*currentChoice === "photo" && <Dcams />*/}
      {currentChoice === "photo" && <Dcams isModular={true} />}
      {currentChoice === "plaid" && <Plaid onClose={clearChoice} />}
      {currentChoice === "mdl" && <LAWallet />}
      {currentChoice === "clear" && <Clear />}
      {currentChoice !== "plaid" && (
        <Group spacing={5} mt="xl">
          <ActionIcon onClick={clearChoice}>
            <IconArrowLeft siz={14} stroke={1} />
          </ActionIcon>
          <Text fz="xs" c="dimmed">
            Go Back
          </Text>
        </Group>
      )}
    </>
  );

  const menu = (
    <Stack
      spacing={8}
      style={{
        display: "flex",
        flexDirection: "column",
        //justifyContent: "space-between", // This will push the button to the bottom
        height: "100%", // This should take the full height of its parent
      }}
    >
      <Box>
        <Center>
          <ThemeIcon
            variant="light"
            c="teal"
            size={inputSize === "md" ? 70 : 65}
            radius={"xl"}
          >
            <IconId
              size={inputSize === "md" ? 50 : 45}
              stroke={1}
              color="teal"
            />
          </ThemeIcon>
        </Center>
      </Box>
      <Box>
        <Stack spacing={5} mt="md">
          <Text fz={inputSize === "md" ? 25 : 18} fw={700}>
            Let's verify you with an ID
          </Text>

          <Text c="dimmed" fz={inputSize === "md" ? 20 : 16}>
            Verify yourself by taking a picture of your ID or use a trusted
            express option.
          </Text>
        </Stack>

        <Stack mt="xl">
          {choices.map((choice, index) => {
            if (choiceVisibility[choice]) {
              const { icon, label, button, isButton } = getIconAndLabel(choice);
              return (
                <React.Fragment key={index}>
                  {isButton ? (
                    <Paper
                      style={{ width: "100%" }}
                      onClick={() => handleChoiceClick(choice)}
                    >
                      {button}
                    </Paper>
                  ) : (
                    <Paper
                      mt={0}
                      withBorder
                      p="md"
                      style={{
                        border: "2px solid #f0eded",
                        transition: "all 0.3s ease",
                        cursor: "pointer",
                        height: "75px",
                        minHeight: "75px",
                      }}
                      onClick={() => handleChoiceClick(choice)}
                    >
                      <Group position="apart" noWrap>
                        <Group noWrap>
                          {React.isValidElement(icon) ? (
                            icon
                          ) : (
                            <Image src={icon} width={40} />
                          )}
                          <Text fz="md" fw={700}>
                            {label}
                          </Text>
                        </Group>
                        <Radio />
                      </Group>
                    </Paper>
                  )}
                  {index === 0 && (
                    <>
                      <Text align="center">or</Text>
                    </>
                  )}
                </React.Fragment>
              );
            }
            return null;
          })}
        </Stack>
      </Box>
    </Stack>
  );

  return <>{currentChoice !== null ? components : menu}</>;
}
