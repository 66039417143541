import React, { useState, useContext, useEffect, useRef } from "react";
import {
  LoadingOverlay,
  FocusTrap,
  Text,
  MantineProvider,
  PinInput,
  Image,
  Title,
  TextInput,
  Button,
  Group,
  Stack,
  Center,
  Container,
  UnstyledButton,
  Flex,
  Paper,
  ThemeIcon,
  Box,
  Tooltip,
  Avatar,
  PasswordInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconShieldCheck, IconPhoneCheck } from "@tabler/icons-react";
import phone_icon from "./icon.svg";
import VXLogo from "../../assets/vx-logo.svg";
import alert from "./alert-circle.svg";
import { DataContext } from "../../context/DataContext";
import { isMobile } from "react-device-detect";
import useStatusCheck from "../../hooks/useStatusCheck";
import { useMediaQuery } from "@mantine/hooks";
import useAdjustInputSize from "../../hooks/useAdjustInputSize";
import FloatingLabelInput from "../../components/floating-label-input";
import DateOfBirthSelect from "../../components/date-of-birth-select";
export default function PhoneMatch() {
  const { data, dispatch, text } = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [isOtpInput, setIsOtpInput] = useState(false);
  const [template, setTemplate] = useState({});
  const [result, setResult] = useState(false);
  const [isDobError, setIsDobError] = useState(false);
  const [openedFnTooltip, setOpenedFnTooltip] = useState(false);
  const [resultStatus, setResultStatus] = useState("NEW");
  const smallScreen = useMediaQuery("(max-width: 40em)");
  const isIframe = window !== window.top;
  const inputSize = useAdjustInputSize();
  const firstNameInputRef = useRef(null);

  const getNewPin = () => {
    setIsOtpInput(false);
    setTemplate({});
    setResult(false);
    form.setFieldValue("pin", "");
  };

  useStatusCheck(result, false, "phonematch");

  const form = useForm({
    initialValues: {
      fn: data.fn || "",
      ln: data.ln || "",
      phone: data.phone || "",
      dob: data.dob || "",
      pin: "",
    },
    validate: (values) => {
      const dobPattern =
        /^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/;
      const isDobFormatCorrect = dobPattern.test(values.dob);

      // Initialize dobError with a default error message.
      let dobError = text.general.dateOfBirthError;

      if (isDobFormatCorrect) {
        const year = parseInt(values.dob.substring(0, 4), 10);
        const month = parseInt(values.dob.substring(4, 6), 10) - 1; // JS months are 0-indexed
        const day = parseInt(values.dob.substring(6, 8), 10);

        const date = new Date(year, month, day);

        // Validate if the constructed date matches the input date components.
        if (
          date.getFullYear() === year &&
          date.getMonth() === month &&
          date.getDate() === day
        ) {
          // The date is valid.
          dobError = null;
        }
      }
      if (isOtpInput) {
        return {
          pin: values.pin.length < 6 ? text.phonematch.code.error : null,
        };
      } else {
        return {
          phone:
            values.phone.replace(/\D/g, "").length !== 10
              ? text.general.phoneError
              : null,
          fn: values.fn.length < 2 ? text.general.firstNameError : null,
          ln: values.ln.length < 2 ? text.general.lastNameError : null,
          dob: dobError,
        };
      }
    },
  });

  const handleDobChange = (dob) => {
    form.setFieldValue("dob", dob.year + dob.month + dob.day);
  };

  const submitHandler = () => {
    const errors = form.validate();

    if (!errors.hasErrors) {
      setLoading(true);
      data.phone = form.values.phone;
      data.fn = form.values.fn;
      data.ln = form.values.ln;
      data.dob = form.values.dob;
      data.isOtpInput = isOtpInput;
      if (isOtpInput) {
        template.codematch.key = form.values.pin;
        data.phonematch_continue = template;
      }

      fetch(
        process.env.REACT_APP_BACKEND_URL +
          "/v3/stable/api/frontend/phonematch",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      )
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {
          setLoading(false);
          if (data.result.action === "PENDING" && !isOtpInput) {
            setTemplate(data.continuations.outofband.template);
            setIsOtpInput(true);
          } else {
            setLoading(true);
            setResult(true);
            //setResultStatus(data.result.action);
          }
        })
        .catch(function () {
          alert("Something Went Wrong! Please try again.");
        });
    }
  };

  useEffect(() => {
    const errors = form.validate();
    if (!errors.hasErrors) {
      submitHandler();
    } else {
      setLoading(false);
      form.clearErrors();
    }
  }, []);

  if (loading) {
    return <LoadingOverlay visible />;
  }

  return (
    <>
      <form
        onSubmit={form.onSubmit(submitHandler)}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // This will push the button to the bottom
          height: "100%", // This should take the full height of its parent
        }}
      >
        <Box>
          {!isOtpInput && (
            <>
              <Stack spacing={8} mt={inputSize === "md" ? 5 : 5}>
                <Center>
                  <ThemeIcon
                    variant="light"
                    c="teal"
                    size={inputSize === "md" ? 60 : 55}
                    radius={"xl"}
                  >
                    <IconPhoneCheck
                      size={inputSize === "md" ? 40 : 35}
                      stroke={1.5}
                      color="teal"
                    />
                  </ThemeIcon>
                </Center>

                <Stack spacing={0} mt="sm">
                  <Text fz={inputSize === "md" ? 24 : 20} fw={700}>
                    {text.phonematch.title}
                  </Text>

                  <Text c="dimmed" fz={inputSize}>
                    {text.phonematch.subTitle}
                  </Text>
                </Stack>
              </Stack>

              <Stack mt={20} spacing={"md"}>
                <Box>
                  <FloatingLabelInput
                    size={"lg"}
                    label={text.general.firstName}
                    placeholder={text.general.firstNamePlaceholder}
                    {...form.getInputProps("fn")}
                  />
                </Box>
                <Box>
                  <FloatingLabelInput
                    size={"lg"}
                    label={text.general.lastName}
                    {...form.getInputProps("ln")}
                  />
                </Box>
                <Box>
                  <DateOfBirthSelect
                    onDobChange={handleDobChange}
                    {...form.getInputProps("dob")}
                  />
                </Box>
                <Box>
                  <FloatingLabelInput
                    size={"lg"}
                    label={text.general.phone}
                    tooltip={text.general.phoneTooltip}
                    placeholder={text.general.phonePlaceholder}
                    inputMode="tel"
                    {...form.getInputProps("phone")}
                    customOnChange={(event) => {
                      let value = event.target.value || "";
                      value = value.replace(/\D/g, "").substring(0, 10);
                      value = value.replace(
                        /(\d{3})(\d{3})(\d{4})/,
                        "$1-$2-$3"
                      );
                      // Call the original onChange from form.getInputProps("phone")
                      form.getInputProps("phone").onChange(value);
                      form.setFieldValue("phone", value);
                    }}
                  />
                </Box>
              </Stack>
            </>
          )}
          {isOtpInput && (
            <>
              <Center mt={inputSize === "md" ? 25 : 10}>
                <Stack align="center">
                  <ThemeIcon
                    variant="light"
                    c="teal"
                    size={inputSize === "md" ? 70 : 65}
                    radius={"xl"}
                  >
                    <IconShieldCheck
                      stroke={1}
                      size={inputSize === "md" ? 50 : 45}
                      color="teal"
                    />
                  </ThemeIcon>

                  <Text fz={inputSize === "md" ? 30 : 23} fw={700}>
                    {text.phonematch.code.title}
                  </Text>
                  <Text
                    ta="center"
                    c="dimmed"
                    fz={inputSize === "md" ? 24 : 14}
                  >
                    {text.phonematch.code.subTitle}
                  </Text>
                </Stack>
              </Center>

              <Group position="center" mt={40}>
                <FocusTrap>
                  <PinInput
                    data-autofocus
                    type="number"
                    oneTimeCode
                    size={"xl"}
                    length={6}
                    {...form.getInputProps("pin")}
                  />
                </FocusTrap>
              </Group>
              <Stack align="center" mt={40} spacing={0}>
                <Text fz="xs" c="dimmed">
                  {text.phonematch.code.newCodeNote}
                </Text>
                <Button onClick={getNewPin} variant="subtle">
                  {text.phonematch.code.newCodeButton}
                </Button>
              </Stack>
            </>
          )}
        </Box>
        <Box pb="sm">
          <Button mt="xl" fullWidth type="submit" size={"lg"}>
            {isOtpInput ? text.phonematch.code.button : text.phonematch.button}
          </Button>
        </Box>
      </form>
    </>
  );
}
