import React, { useContext, useState } from "react";
import {
  LoadingOverlay,
  Button,
  Container,
  Flex,
  Paper,
  Text,
  Box,
  Stack,
  ThemeIcon,
  Group,
} from "@mantine/core";
import YotiFaceCapture from "../agematch/estimation/yoti/capture";
import { useParams } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import ErrorMessage from "../../components/error-message";
import Lottie from "react-lottie-player";
import AnimationFaceCapture from "../../assets/animation_face_capture.json";
import {
  IconFaceId,
  IconSunHigh,
  IconChefHatOff,
  IconEyeglass2,
} from "@tabler/icons-react";
export default function FaceCapture({ onCompletion }) {
  const { sdk } = useParams();
  const { data, settings, setSessionState } = useContext(DataContext);
  const [isCapture, setIsCapture] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleReady = () => {
    setLoading(false);
  };

  const handleLoading = () => {
    setLoading(true);
  };

  const handleComplete = () => {
    setIsComplete(true);
    setLoading(false);
    setIsCapture(false);
    if (typeof onCompletion === "function") {
      onCompletion();
    }
  };

  const handleError = () => {
    setLoading(false);
  };

  const startCapture = () => {
    setLoading(true);
    setIsCapture(true);
  };

  return (
    <>
      {loading && <LoadingOverlay visible />}
      {!isCapture ? (
        <>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between", // This will push the button to the bottom
              height: "100%", // This should take the full height of its parent
            }}
          >
            <Box>
              <Stack align="center">
                <ThemeIcon variant="light" c="teal" size={85} radius={"xl"}>
                  <IconFaceId size={70} stroke={1} />
                </ThemeIcon>
                <Text fz={30} fw={700} align="center">
                  Face Match to ID Document
                </Text>
              </Stack>
              <Text align="center">
                Before you get started ensure the following:
              </Text>
              <Paper
                withBorder
                m="md"
                p="md"
                style={{ backgroundColor: "#fafafa" }}
              >
                <Stack spacing={"xl"}>
                  <Group noWrap>
                    <IconSunHigh size={30} />
                    <Text fz={18}>You are in a well-lit area</Text>
                  </Group>
                  <Group noWrap>
                    <IconChefHatOff size={30} />
                    <Text fz={18}>Remove any headwear</Text>
                  </Group>
                  <Group noWrap>
                    <IconEyeglass2 size={30} />
                    <Text fz={18}>Keep your glasses on</Text>
                  </Group>
                </Stack>
              </Paper>
            </Box>
            <Box>
              <Text fz="sm" c="dimmed" align="center">
                We will ask you to allow camera permissions
              </Text>
              <Button size="lg" onClick={startCapture} fullWidth>
                Get Started
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <YotiFaceCapture
          onReady={handleReady}
          onLoading={handleLoading}
          onComplete={handleComplete}
          onError={handleError}
        />
      )}
    </>
  );
}
