import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ClearStart from "./clear-start";
import ClearReturn from "./clear-return";

const Clear = () => {
  const location = useLocation();

  // Check if the current path includes 'return'
  const isReturnPath = location.pathname.includes("/return");

  return <>{isReturnPath ? <ClearReturn /> : <ClearStart />}</>;
};

export default Clear;
