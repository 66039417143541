import React, { useEffect, useRef, useContext } from "react";
import { uploadImageToS3 } from "../../../../../helpers";
import { DataContext } from "../../../../../context/DataContext";

const YotiFaceCapture = ({
  onReady,
  onLoading,
  onComplete,
  onError,
  language,
}) => {
  const { settings } = useContext(DataContext);
  const rootRef = useRef(null);
  let fcm = null;
  const errorTimerRef = useRef(null);

  useEffect(() => {
    if (window.Yoti && window.Yoti.FaceCaptureModule) {
      onReady();
      const props = {
        faceCaptureAssetsRootUrl: "/assets/face-capture/",
        manualCaptureFallback: false,
        showInitialGuidance: false,
        showGetHelpButton: false,
        encryptImage: false,
        secure: false,
        clientSdkId: "48c6f969-16be-493b-8d7a-8d6f6d7544cc",
        language: language,
        onSuccess: async (data) => {
          clearTimeout(errorTimerRef.current);
          onLoading();
          const base64Response = await fetch(
            `data:image/jpeg;base64,${data.img}`
          );
          const blob = await base64Response.blob();
          const suffix = "selfie";
          const imageKey = `${settings.request_id}_dl_${suffix}.jpeg`;
          try {
            const upload = await uploadImageToS3(imageKey, blob);

            onComplete();
          } catch (error) {
            onError();
          }
          unmount();
        },
        onError: (error) => {
          clearTimeout(errorTimerRef.current);
          onError();
          unmount();
        },
      };
      fcm = window.Yoti.FaceCaptureModule.render(props, rootRef.current);

      errorTimerRef.current = setTimeout(() => {
        onError();
        unmount();
      }, 20000);

      return () => {
        if (fcm) {
          fcm.unmount();
        }
        clearTimeout(errorTimerRef.current);
      };
    }
  }, []);

  const unmount = () => {
    if (fcm) {
      onReady(false);
      fcm.unmount();
    }
  };

  return (
    <div
      ref={rootRef}
      id="face-capture-module-root"
      style={{
        position: "fixed", // or 'absolute' if you want it relative to a positioned ancestor
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 2999,
      }}
    ></div>
  );
};

export default YotiFaceCapture;
