import React, { useState, useEffect, useContext } from "react";
import {
  LoadingOverlay,
  Box,
  Container,
  NativeSelect,
  Alert,
  Select,
  Text,
  TextInput,
  Button,
  Group,
  Stack,
  Center,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { DataContext } from "../../../context/DataContext";
import {
  IconShieldCheck,
  IconShieldCancel,
  IconPhoneCheck,
} from "@tabler/icons-react";
import useStatusCheck from "../../../hooks/useStatusCheck";
import DataCollectionForm from "../../../components/data-collection-form";
export default function IDMatch() {
  const { text } = useContext(DataContext);
  const formConfig = {
    fields: [
      {
        name: "fn",
        label: text.general.firstName,
        initialValue: "",
        validation: (value) =>
          value.length < 2 ? text.general.firstNameError : null,
      },
      {
        name: "ln",
        label: text.general.lastName,
        initialValue: "",
        validation: (value) =>
          value.length < 2 ? text.general.lastNameError : null,
      },
      {
        name: "dob",
        label: text.general.DateOfBirth,
        type: "date",
        validation: (value) => {
          const dobPattern =
            /^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/;
          const isDobFormatCorrect = dobPattern.test(value);

          // Initialize dobError with a default error message.
          let dobError = text.general.DateOfBirthError;

          if (isDobFormatCorrect) {
            const year = parseInt(value.substring(0, 4), 10);
            const month = parseInt(value.substring(4, 6), 10) - 1; // JS months are 0-indexed
            const day = parseInt(value.substring(6, 8), 10);

            const date = new Date(year, month, day);

            if (
              date.getFullYear() === year &&
              date.getMonth() === month &&
              date.getDate() === day
            ) {
              // The date is valid.
              dobError = null;
            }
          }
          return dobError;
        },
      },
      {
        name: "ssn",
        label: text.general.lastFourSSN,
        initialValue: "",
        isPassword: true,
        validation: (value) =>
          value.length !== 4 ? text.general.lastFourSSNError : null,
      },
    ],
  };

  return (
    <DataCollectionForm
      config={formConfig}
      title={text.coppa.title}
      subtitle={text.coppa.subTitle}
      buttonText={text.coppa.button}
      icon={
        <>
          <IconShieldCheck size={40} stroke={1.5} color="teal" />
        </>
      }
      componentKey={"idmatch/coppa"}
      endpoint="/v3/stable/api/frontend/idmatchCoppa"
      reAttempts={1}
    />
  );
}
