import React, { useEffect, useRef, useContext, useState } from "react";
import { DataContext } from "../../../context/DataContext";
import {
  Center,
  LoadingOverlay,
  Stack,
  Text,
  Box,
  Paper,
  Group,
  Loader,
  Image,
} from "@mantine/core";
import { IconBolt, IconLock, IconHeart } from "@tabler/icons-react";
import useStatusCheck from "../../../hooks/useStatusCheck";
import YotiDigitalIdIcon from "../../../assets/yoti_digital_id_icon.svg";
const YotiID = ({ onReady }) => {
  const { data, settings, setSessionState } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isComplete, setIsComplete] = useState(false);
  const buttonRef = useRef(null);
  const scriptLoaded = useRef(false);
  useStatusCheck(isComplete, false, "idmax");
  useEffect(() => {
    if (scriptLoaded.current) {
      return;
    }

    const script = document.createElement("script");
    script.src = "https://www.yoti.com/share/client/";
    script.onload = () => {
      if (window.Yoti && window.Yoti.Share) {
        const yoti = window.Yoti.Share.init({
          elements: [
            {
              domId: buttonRef.current.id,
              scenarioId: "9520b486-6386-49a8-8011-f488fc79ba7b",
              clientSdkId: "8b7af083-1a12-4c85-b333-1b6225e9b18e",
              type: "inline",
              displayLearnMoreLink: false,
              skinId: "digital-id-uk",
              qr: {
                title: " ",
              },
              shareComplete: {
                mobileFlow: "internal",
                errorHandler: (e) => {
                  //console.log("yoti error", e);
                },
                tokenHandler: (token, done) => {
                  data.yoti_token = token;
                  data.provider = "YOTI";
                  fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/v3/stable/api/frontend/idmax`,
                    {
                      method: "POST",
                      body: JSON.stringify(data),
                    }
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      done();
                      setIsComplete(true);
                      //console.log("yoti data", data);
                    })
                    .catch((error) => {
                      console.error("Error fetching data:", error);
                      done();
                    });
                },
              },
            },
          ],
        });
        scriptLoaded.current = true;
        setTimeout(() => {
          setIsLoading(false);
          onReady && onReady();
        }, 700);
      }
    };
    document.head.appendChild(script);

    return () => {
      script.remove();
    };
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Box>
        {!isLoading && (
          <Stack align="center">
            <Group spacing={5}>
              <Image src={YotiDigitalIdIcon} width={25} />
              <Text fz="xl" fw={700} align="center">
                Use Your Digital ID App
              </Text>
            </Group>

            <Paper p="lg" withBorder style={{ backgroundColor: "#fafafa" }}>
              <Stack>
                <Group noWrap>
                  <IconBolt size={20} />
                  <Text fz="md">Instantly prove your identity</Text>
                </Group>
                <Group noWrap>
                  <IconLock size={20} />
                  <Text fz="md">Keep your data safe and secure</Text>
                </Group>
                <Group noWrap>
                  <IconHeart size={20} />
                  <Text fz="md">No need to provide documents</Text>
                </Group>
              </Stack>
            </Paper>
          </Stack>
        )}
      </Box>
      <Box>
        <div ref={buttonRef} id="veratad_yoti_id">
          {isLoading && (
            <Center>
              <Loader />
            </Center>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default YotiID;
